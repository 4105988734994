import React from "react";
import { useTranslation } from "react-i18next";
import { Filter, Grid, useFilterContext } from "@programari-limit/base-react";
import { Box, Icon, IconButton, Tooltip } from "@mui/material";
import MuiGrid from "@mui/material/Grid";
import GridFilterField from "components/GridFilterField";
import { SpringFilterQueryBuilder } from "utils/spring-filter-query-builder";
import { columnesZones } from "sharedAdvancedSearch/advancedSearchColumns";

const FilterContent = (props) => {
    const { filterApiRef } = props;
    const { t } = useTranslation();
    const { data } = useFilterContext();

    return (
        <MuiGrid container spacing={2}>
            <GridFilterField
                name="rutaData"
                xs={12}
                sm={4}
                md={4}
                lg={2.5}
                xl={2}
            />
            <GridFilterField
                name="recurs"
                xs={10}
                sm={6}
                md={6}
                lg={4}
                xl={3}
            />
            <MuiGrid xs={0.5} sm={0.5} md={0.5} lg={5} xl={6.5} />
            <MuiGrid item lg={0.5}>
                <Tooltip title={t("comu.resetFiltre")}>
                    <IconButton
                        onClick={() => filterApiRef?.current?.doReset()}
                    >
                        <Icon>filter_alt_off</Icon>
                    </IconButton>
                </Tooltip>
            </MuiGrid>
        </MuiGrid>
    );
};

const RutesFilter = (props) => {
    const { onSpringFilterChange, onFilterInitialized } = props;
    const filterApiRef = React.useRef();

    return (
        <Box sx={{ mb: 2 }}>
            <Filter
                name={"RTP_FILTER"}
                resourceName={"rutaPunt"}
                // persistentState
                springFilterBuilder={(data) => springFilterBuilder(data)}
                onSpringFilterChange={onSpringFilterChange}
                onFilterInitialized={onFilterInitialized}
                size="small"
                apiRef={filterApiRef}
                initialOnChangeRequest
            >
                <FilterContent filterApiRef={filterApiRef} />
            </Filter>
        </Box>
    );
};

const springFilterBuilder = (data) => {
    const { and, equal } = SpringFilterQueryBuilder;

    const filter = and(
        equal("ruta.recurs.id", data?.recurs?.id),
        // equal("ruta.id", data?.ruta?.id),
        equal("rutaData", data?.rutaData)
    ).toString();

    return filter;
};

const PuntsRuta = () => {
    const [fixedFilter, setFixedFilter] = React.useState();
    const [filterInitialized, setFilterInitialized] = React.useState(false);
    const { t } = useTranslation();

    const columns = [
        {
            name: "zona",
            flex: 1,
            formFieldProps: {
                advancedSearchColumns: columnesZones,
                advancedSearchMaxWidth: "sm",
            },
        },
        {
            name: "hora",
            flex: 0.5,
        },
        {
            name: "pugen",
            flex: 0.5,
        },
        {
            name: "baixen",
            flex: 0.5,
        },
        {
            name: "total",
            flex: 0.5,
        },
        {
            name: "nous",
            flex: 0.5,
        },
        {
            name: "totalNous",
            flex: 0.5,
        },
        {
            name: "observacions",
            flex: 1.5,
        },
    ];

    return (
        <Grid
            title={t("rutes.tabs.punt.title")}
            resourceName="rutaPunt"
            columns={columns}
            findDisabled={!filterInitialized}
            fixedFilter={fixedFilter}
            readOnly
            toolbarAdditionalRow={
                <RutesFilter
                    onSpringFilterChange={setFixedFilter}
                    onFilterInitialized={() => setFilterInitialized(true)}
                />
            }
            density="compact"
            defaultSortModel={[
                {
                    field: "hora",
                    sort: "asc",
                },
            ]}
        />
    );
};

export default PuntsRuta;
