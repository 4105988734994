import React from "react";
import { useTranslation } from "react-i18next";
import { Filter, Grid, useFormContext } from "@programari-limit/base-react";
import MuiGrid from "@mui/material/Grid";
import GridFormField from "components/GridFormField";
import {
    columnesRuta,
    columnesTripulant,
} from "sharedAdvancedSearch/advancedSearchColumns";
import GridFilterField from "components/GridFilterField";
import { Box, Icon, IconButton, Tooltip } from "@mui/material";
import { SpringFilterQueryBuilder } from "utils/spring-filter-query-builder";

const RutaTripulantForm = ({ ocultarRuta }) => {
    return (
        <MuiGrid container spacing={2} sx={{ mt: 1 }}>
            {!ocultarRuta && (
                <GridFormField
                    name="ruta"
                    xs={12}
                    advancedSearchColumns={columnesRuta}
                    advancedSearchMaxWidth="lg"
                />
            )}
            <GridFormField
                name="tripulant"
                xs={12}
                advancedSearchColumns={columnesTripulant}
                advancedSearchMaxWidth="lg"
            />
            <GridFormField name="rol" xs={12} />
            <GridFormField name="observacions" xs={12} />
        </MuiGrid>
    );
};

const FilterContent = (props) => {
    const { filterApiRef } = props;
    const { t } = useTranslation();

    return (
        <MuiGrid container spacing={2}>
            <GridFilterField name="dataInici" sm={6} md={6} lg={3} xl={2} />
            <GridFilterField name="dataFi" sm={6} md={6} lg={3} xl={2} />
            <GridFilterField
                name="ruta"
                sm={12}
                md={12}
                lg={6}
                xl={3}
                advancedSearchColumns={columnesRuta}
            />
            <GridFilterField
                name="tripulant"
                sm={12}
                md={12}
                lg={6}
                xl={2.5}
                advancedSearchColumns={columnesTripulant}
            />
            <GridFilterField
                name="rol"
                xs={10}
                sm={6}
                md={6}
                lg={5}
                xl={2}
            />
            <MuiGrid item lg={0.5}>
                <Tooltip title={t("comu.resetFiltre")}>
                    <IconButton
                        onClick={() => filterApiRef?.current?.doReset()}
                    >
                        <Icon>filter_alt_off</Icon>
                    </IconButton>
                </Tooltip>
            </MuiGrid>
        </MuiGrid>
    );
};

const RutesFilter = (props) => {
    const { onSpringFilterChange, onFilterInitialized } = props;
    const filterApiRef = React.useRef();

    return (
        <Box sx={{ mb: 2 }}>
            <Filter
                name={"RTT_FILTER"}
                resourceName={"rutaTripulant"}
                persistentState
                springFilterBuilder={(data) => springFilterBuilder(data)}
                onSpringFilterChange={onSpringFilterChange}
                onFilterInitialized={onFilterInitialized}
                size="small"
                apiRef={filterApiRef}
            >
                <FilterContent filterApiRef={filterApiRef} />
            </Filter>
        </Box>
    );
};

const springFilterBuilder = (data) => {
    const { and, equal, ge, le } = SpringFilterQueryBuilder;

    const filter = and(
        ge("ruta.data", data?.dataInici ? data?.dataInici  : undefined),
        le("ruta.data", data?.dataFi ? data?.dataFi  : undefined),
        equal("ruta.id", data?.ruta?.id),
        equal("tripulant.id", data?.tripulant?.id),
        equal("rol", data?.rol)
    ).toString();

    return filter;
};

export const RutaTripulant = (props) => {
    const { data } = props;
    const { t } = useTranslation();
    const [fixedFilter, setFixedFilter] = React.useState();
    const [filterInitialized, setFilterInitialized] = React.useState(false);

    const columns = [
        {
            name: "tripulant",
            flex: 1.5,
        },
        {
            name: "rol",
            flex: 0.75,
        },
        {
            name: "observacions",
            flex: 1,
        },
    ];

    !data &&
        columns.unshift({
            name: "ruta",
            flex: 1.5,
        });

    return (
        <Grid
            title={t("tripulantRuta.title")}
            resourceName="rutaTripulant"
            findDisabled={!filterInitialized}
            fixedFilter={data ? `ruta.id : ${data.id}` : fixedFilter}
            columns={columns}
            toolbarAdditionalRow={
                !data && (
                    <RutesFilter
                        onSpringFilterChange={setFixedFilter}
                        onFilterInitialized={() => setFilterInitialized(true)}
                    />
                )
            }
            formPopupEditable
            formPopupComponent={<RutaTripulantForm ocultarRuta={!!data} />}
            formPopupTitleCreate={t("tripulantRuta.create")}
            formPopupTitleUpdate={t("tripulantRuta.update")}
            formPopupSize="sm"
            formPopupStaticData={data ? { ruta: { id: data.id } } : undefined}
            density="compact"
        />
    );
};

export default RutaTripulant;
